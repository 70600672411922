
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        







































































.VInput {
  transition: 100ms;
  cursor: none;
}
.VInput__input {
  width: 100%;
  cursor: none;
  border-radius: 3.5vh;
  margin-top: 8px;
  padding: 2.2vh 5vw;
  border: 1px solid #e7e9eb;
  font-size: 2vh;
  font-weight: 400;
  font-family: @Euclid;
  background: #fff;
  transition: 100ms;
  @media screen and (min-width: 768px) {
    padding: 1.5vh 1.7vw;
    font-size: 1.2vh;
    border-radius: 2.5vh;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1.4vh;
  }
}
.VInput__input::placeholder {
  color: #b1b4b7;
  font-size: 2vh;
  font-weight: 300;
  font-family: @EuclidLight;
  @media screen and (min-width: 768px) {
    font-size: 1.2vh;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1.4vh;
  }
}
.VInput__label {
  display: block;
  font-size: 2vh;
  cursor: none;
  @media screen and (min-width: 768px) {
    font-size: 1.4vh;
  }
}
.VInput__input.focused {
  border: 1px solid #000;
}
.VInput__input.invalid {
  border: 1px solid #d8394c;
  color: #d8394c;
}
.VInput__input.invalid::placeholder {
  color: #d8394c;
}
