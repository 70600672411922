@import url(../../assets/fonts/stylesheet.css);
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 33px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.5px solid #ecebed;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 2px;
  top: 2px;
  border: 0.5px solid #ecebed;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider:before {
  left: 6px;
  background: #28e1b9;
  border: 0.5px solid #28e1b9;
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
