
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        


































































































































































































































































































































































































































































































































































































































.fos-page {
  .half__pic {
    height: 46vh;
    @media screen and (min-width: 768px) {
      height: 100vh;
    }
  }
}
.fos-content {
  font-size: 2vh;
  overflow-y: auto;
  @media screen and (min-width: 768px) {
    font-size: 1.2vh;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.4vh;
  }
  .h2 {
    font-size: 5vh;
    @media screen and (min-width: 1024px) {
      font-size: 5vh;
      margin-bottom: 1vh;
    }
  }
}
.fos-form-wrapper {
  height: 100%;
  padding-bottom: 4.58333333vw;
}
.fos-form--full-height {
  height: 100%;
}
.fos-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 18px;
  width: 100%;
  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 26px;
  }
}
.fos-form-col {
  box-sizing: border-box;
  align-self: stretch;
  justify-self: stretch;
}
.fos-form-full {
  box-sizing: border-box;
  grid-column: 1;
  @media screen and (min-width: 1024px) {
    grid-column: 1 / span 2;
  }
}
.fos-submit {
  text-align: center;
  button {
    width: 100%;
    padding: 3vh 0;
    font-size: 3vh;
  }
  @media screen and (min-width: 768px) {
    button {
      margin: 1vh 0;
      padding: 1.4vh 2vw;
      font-size: 2vh;
    }
  }
  @media screen and (min-width: 1024px) {
    text-align: right;
    button {
      width: auto;
      padding: 1.7vh 2vw;
      font-size: 2vh;
    }
  }
}
.fos-form-primary {
  color: #262626;
}
.fos-form-secondary {
  color: #6d7275;
}
.file {
  padding: 2vh 2vh 2vh 6vh;
  position: relative;
  width: 100%;
  border: 2px dashed #000;
  border-radius: 3.5vh;
  cursor: none;
  @media screen and (min-width: 768px) {
    padding: 1.5vh 1.5vh 1.5vh 6vh;
    border-radius: 2.5vh;
  }
  .input-file {
    opacity: 0;
    position: absolute;
  }
}
.fos-form-files {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.fos-form-file {
  position: relative;
  display: inline-block;
  padding: 1vh 5vh 1vh 2vh;
  background: #f6f7fb;
  border-radius: 3vh;
  color: #262626;
  margin: 0.8vh;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 230px;
  @media screen and (min-width: 1024px) {
    margin: 0.5vh;
    padding: 0.8vh 4vh 0.8vh 1.5vh;
    max-width: 100%;
  }
}
// .fos-form-file + .fos-form-file {
//   margin-left: 0;
//   @media screen and (min-width: 1024px) {
//     margin-left: 1vh;
//   }
// }
.fos-form-file__remove {
  position: absolute;
  right: 0.6vh;
  width: 4vh;
  height: 4vh;
  top: calc(50% - 2vh);
  font-size: 3.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transform: rotate(0.125turn);
  @media screen and (min-width: 1024px) {
    top: calc(50% - 1vh);
    right: 1vh;
    width: 2vh;
    height: 2vh;
    font-size: 2.5vh;
  }
}
.fos-form-agreement {
  display: flex;

  &-title {
    margin-left: 15px;
  }
}
.hr {
  height: 1vh;
  border-bottom: 2px solid #b1b4b7;
}
.FOSStep__mobile {
  text-align: center;
}
.FOSStep__mobile--button {
  display: block;
  text-decoration: none;
  background: @green;
  padding: 1.7vh 2vw;
  font-family: @Euclid;
  font-size: 2vh;
  border-radius: 5vh;
  border: none;
  text-transform: lowercase;
  cursor: none;
  transition: 200ms;

  width: 100%;
  margin: 5vh 0;
  padding: 3vh 0;
  font-size: 3vh;
  @media screen and (min-width: 1024px) {
    width: auto;
    margin: 3vh 0;
    padding: 1.7vh 2vw;
    font-size: 2vh;
  }
}
.fos-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(38, 38, 38, 0.8);
}
.fos-contacts {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 2px solid black;
  padding-bottom: 2vh;
  margin-bottom: 2vh;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.fos-contacts__contact:not(:last-child),
.fos-form__sub-title {
  margin-bottom: 1vh;
}
.fos-contacts button a {
  text-decoration: none;
}
.fos-popup-close {
  position: absolute;
  top: 2vh;
  right: 2vh;
  font-size: 3vh;
  transform: rotate(0.125turn);
  background: #e6e6ea;
  border-radius: 100%;
  width: 3vh;
  height: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0.4vh 0;
}
.fos-popup-content {
  position: relative;
  box-sizing: border-box;
  min-width: 100%;
  min-height: auto;
  max-width: 60vw;
  max-height: 80vh;
  padding: 4vh;
  background: #fff;
  border-radius: 2vh;
  font-size: 2vh;
  @media screen and (min-width: 768px) {
    font-size: 1.2vh;
    min-width: 526px;
    min-height: 375px;
    max-width: 60vw;
    max-height: 40vh;
    padding: 3vh;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.4vh;
  }
  .h3 {
    font-size: 4vh;
    text-transform: none;
    @media screen and (min-width: 1024px) {
      font-size: 2.5vh;
      margin-bottom: 1vh;
    }
  }
  .sub-title {
    margin-bottom: 3vh;
  }
  .VInput {
    width: 200px;
  }
  .VInput__input {
    margin: 0;
    padding: 2vh 4vh;
    border: 1px solid #28e1b9;
    @media screen and (min-width: 1024px) {
      padding: 1vh 2vh;
    }
  }
}
.captcha-image {
  margin-bottom: 1vh;
  border-radius: 8px;
  width: 200px;
  height: auto;
}
.button-mail {
  width: 100%;
  text-transform: uppercase;
  background-color: #fff;
}

.fos-popup-submit {
  width: 6vh;
  height: 6vh;
  background: #f0f0f0;
  box-sizing: border-box;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100vh;
  margin-left: 1vh;

  @media screen and (min-width: 1024px) {
    width: 4vh;
    height: 4vh;
  }
  &.active {
    background: #28e1b9;
    svg {
      fill: #262626;
    }
  }
  svg {
    fill: #a7a2a9;
    width: 60%;
    height: 60%;
  }
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
