
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        


























































































































.VSelect {
  position: relative;
  font-size: 2vh;
  transition: 100ms;
  @media screen and (min-width: 768px) {
    font-size: 1.4vh;
  }
}
.VSelect__filed {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  cursor: none;
  border-radius: 3.5vh;
  margin-top: 8px;
  padding: 2.2vh 5vw;
  border: 1px solid #e7e9eb;
  font-size: 2vh;
  font-weight: 400;
  font-family: @Euclid;
  background: #fff;
  user-select: none;
  transition: 100ms;
  @media screen and (min-width: 768px) {
    padding: 1.5vh 1.7vw;
    font-size: 1.2vh;
    border-radius: 2.5vh;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1.4vh;
  }
}
.VSelect__filed::after {
  content: '';
  position: absolute;
  background-image: url(../../assets/images/keyboard_arrow_down-24px.svg);
  background-size: 2.5vh 2.5vh;
  background-position: center center;
  background-repeat: no-repeat;
  width: 2vh;
  height: 2vh;
  right: 1.2vh;
  top: calc(50% - 1vh);
  transition: 100ms;
}
.VSelect__filed.focused::after {
  transform: rotate(180deg);
}
.VSelect__placeholder {
  color: #b1b4b7;
  font-size: 2vh;
  font-weight: 300;
  font-family: @EuclidLight;
  user-select: none;
  @media screen and (min-width: 768px) {
    font-size: 1.2vh;
  }
  @media screen and (min-width: 1600px) {
    font-size: 1.4vh;
  }
}
.disabled {
  background: #e5e6e8;
  color: #b1b4b7;
}
.VSelect__filed.focused,
.VSelect__placeholder.focused {
  border: 1px solid #000;
}
.VSelect__placeholder.invalid,
.VSelect__items.invalid {
  border: 1px solid #d8394c;
  color: #d8394c;
}
.VSelect__items--wrapper {
  position: absolute;
  width: 100%;
  margin-top: 8px;
  padding: 1vh;
  box-shadow: 0px 4px 14px rgba(38, 38, 38, 0.18);
  border-radius: 3.5vh;
  background: #fff;
  z-index: 9999;
  @media screen and (min-width: 768px) {
    border-radius: 2vh;
  }
}
.VSelect__items {
  width: 100%;
  max-height: 20vh;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0.5vh 3vw;
  @media screen and (min-width: 768px) {
    padding: 0.2vh 0.5vw;
  }
}
.VSelect__item {
  font-size: 2vh;
  font-weight: 300;
  font-family: @EuclidLight;
  padding: 1vh 0;
  @media screen and (min-width: 768px) {
    font-size: 1.4vh;
  }
}
.VSelect__item + .VSelect__item {
  border-top: 1px solid #e7e9eb;
}
.VSelect__error {
  font-size: 2vh;
  color: #d8394c;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 1.2vh;
  }
}
.VSelect__errorIcon {
  display: block;
  width: 26px;
  height: 26px;
  margin: 1vh auto 0.4vh;
}
